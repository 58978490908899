import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'prismjs/themes/prism-coy.css';
import './assets/styles/layout.scss';
import './assets/demo/flags/flags.css';
// import bootstrap
import 'bootstrap/dist/css/bootstrap.css';
// Import animate css
import 'animate.css';
// Vue prunt nb
import print from 'vue3-print-nb'

import { createApp, reactive } from 'vue';
import router from './router';
import AppWrapper from './AppWrapper.vue';
import PrimeVue from 'primevue/config';
import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Chips from 'primevue/chips';
import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import Image from 'primevue/image';
import InlineMessage from 'primevue/inlinemessage';
import Inplace from 'primevue/inplace';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import Knob from 'primevue/knob';
import Galleria from 'primevue/galleria';
import Listbox from 'primevue/listbox';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OrderList from 'primevue/orderlist';
import OrganizationChart from 'primevue/organizationchart';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import PickList from 'primevue/picklist';
import ProgressBar from 'primevue/progressbar';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import SelectButton from 'primevue/selectbutton';
import ScrollPanel from 'primevue/scrollpanel';
import ScrollTop from 'primevue/scrolltop';
import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
import Skeleton from 'primevue/skeleton';
import SplitButton from 'primevue/splitbutton';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import Steps from 'primevue/steps';
import StyleClass from 'primevue/styleclass';
import TabMenu from 'primevue/tabmenu';
import Tag from 'primevue/tag';
import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Timeline from 'primevue/timeline';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tooltip from 'primevue/tooltip';
import ToggleButton from 'primevue/togglebutton';
import Tree from 'primevue/tree';
import TreeSelect from 'primevue/treeselect';
import TreeTable from 'primevue/treetable';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import { GoogleMap, Marker } from 'vue3-google-map'

import CodeHighlight from './AppCodeHighlight';
import BlockViewer from './BlockViewer';
import moment from 'moment';

moment.locale('es');

router.beforeEach(function(to, from, next) {
    window.scrollTo(0, 0);
    next();
});

const app = createApp(AppWrapper);

app.config.globalProperties.$appState = reactive({
    theme: 'lara-light-indigo',
    darkTheme: true,
});

app.use(PrimeVue, {
    ripple: true,
    inputStyle: 'outlined',
    locale: {
        am: 'AM',
        pm: 'PM',
        closeText: 'Cerrar',
        prevText: 'Anterior',
        nextText: 'Siguiente',
        monthNames: [
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
        ],
        monthNamesShort: [
            'Ene',
            'Feb',
            'Mar',
            'Abr',
            'May',
            'Jun',
            'Jul',
            'Ago',
            'Sep',
            'Oct',
            'Nov',
            'Dic',
        ],
        dayNames: [
            'Domingo',
            'Lunes',
            'Martes',
            'Miércoles',
            'Jueves',
            'Viernes',
            'Sábado',
        ],
        accept: 'Si',
        reject: 'No',
        dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        weekHeader: 'Semana',
        weekNumberTitle: 'S',
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: '',
        timeOnlyTitle: 'Sólo hora',
        timeText: 'Tiempo',
        hourText: 'Hora',
        minuteText: 'Minuto',
        secondText: 'Segundo',
        millisecondText: 'Milisegundo',
        currentText: 'Fecha actual',
        ampm: false,
        year: 'Año',
        month: 'Mes',
        week: 'Semana',
        day: 'Día',
        today: 'Hoy',
        clear: 'Limpiar',
        list: 'Agenda',
        emptyFilterMessage: 'No hay opciones disponibles',
        emptyMessage: 'No se encontraron resultados',
        allDayText: 'Todo el día',
        moreLinkText: 'Más....',
        noEventsText: 'No hay eventos',
        aria: {
            'paginator.PAGE': 'Página {0}',
            'calendar.BUTTON': 'Mostrar calendario',
            'datatable.sort.ASC': 'ordenar columna ascendente',
            'datatable.sort.DESC': 'ordenar columna descendente',
            'datatable.sort.NONE': 'eliminar la clasificación en la columna',
            'columntoggler.CLOSE': 'Cerrar',
            'overlaypanel.CLOSE': 'Cerrar',
            trueLabel: 'True',
            falseLabel: 'False',
            nullLabel: 'Not Selected',
            star: '1 star',
            stars: '{star} stars',
            selectAll: 'All items selected',
            unselectAll: 'All items unselected',
            close: 'Close',
            previous: 'Previous',
            next: 'Next',
            navigation: 'Navigation',
            scrollTop: 'Scroll Top',
            moveTop: 'Move Top',
            moveUp: 'Move Up',
            moveDown: 'Move Down',
            moveBottom: 'Move Bottom',
            moveToTarget: 'Move to Target',
            moveToSource: 'Move to Source',
            moveAllToTarget: 'Move All to Target',
            moveAllToSource: 'Move All to Source',
            pageLabel: '{page}',
            firstPageLabel: 'First Page',
            lastPageLabel: 'Last Page',
            nextPageLabel: 'Next Page',
            prevPageLabel: 'Previous Page',
            rowsPerPageLabel: 'Rows per page',
            jumpToPageDropdownLabel: 'Jump to Page Dropdown',
            jumpToPageInputLabel: 'Jump to Page Input',
            selectRow: 'Row Selected',
            unselectRow: 'Row Unselected',
            expandRow: 'Row Expanded',
            collapseRow: 'Row Collapsed',
            showFilterMenu: 'Show Filter Menu',
            hideFilterMenu: 'Hide Filter Menu',
            filterOperator: 'Filter Operator',
            filterConstraint: 'Filter Constraint',
            editRow: 'Row Edit',
            saveEdit: 'Save Edit',
            cancelEdit: 'Cancel Edit',
            listView: 'List View',
            gridView: 'Grid View',
            slide: 'Slide',
            slideNumber: '{slideNumber}',
            zoomImage: 'Zoom Image',
            zoomIn: 'Zoom In',
            zoomOut: 'Zoom Out',
            rotateRight: 'Rotate Right',
            rotateLeft: 'Rotate Left'
        },
        messages: {
            //optional for Client Side Validation
            'javax.faces.component.UIInput.REQUIRED': '{0}: Error de validación: se necesita un valor.',
            'javax.faces.converter.IntegerConverter.INTEGER': "{2}: '{0}' debe ser un número formado por uno o varios dígitos.",
            'javax.faces.converter.IntegerConverter.INTEGER_detail': "{2}: '{0}' debe ser un número entre -2147483648 y 2147483647. Ejemplo: {1}",
            'javax.faces.converter.DoubleConverter.DOUBLE': "{2}: '{0}' debe ser un número formado por uno o varios dígitos.",
            'javax.faces.converter.DoubleConverter.DOUBLE_detail': "{2}: '{0}' debe ser un número entre 4.9E-324 y 1.7976931348623157E308  Ejemplo: {1}",
            'javax.faces.converter.BigDecimalConverter.DECIMAL': "{2}: '{0}' debe ser un número decimal positivo o negativo.",
            'javax.faces.converter.BigDecimalConverter.DECIMAL_detail': "{2}: '{0}' debe ser un número decimal positivo o negativo formado por cero o más dígitos, que pueden incluir a continuación una coma decimal y una fracción.  Ejemplo: {1}",
            'javax.faces.converter.BigIntegerConverter.BIGINTEGER': "{2}: '{0}' debe ser un número formado por uno o varios dígitos.",
            'javax.faces.converter.BigIntegerConverter.BIGINTEGER_detail': "{2}: '{0}' debe ser un número formado por uno o varios dígitos. Ejemplo: {1}",
            'javax.faces.converter.ByteConverter.BYTE': "{2}: '{0}' debe ser un número entre 0 y 255.",
            'javax.faces.converter.ByteConverter.BYTE_detail': "{2}: '{0}' debe ser un número entre 0 y 255.  Ejemplo: {1}",
            'javax.faces.converter.CharacterConverter.CHARACTER': "{1}: '{0}' debe ser un carácter válido.",
            'javax.faces.converter.CharacterConverter.CHARACTER_detail': "{1}: '{0}' debe ser un carácter ASCII válido.",
            'javax.faces.converter.ShortConverter.SHORT': "{2}: '{0}' debe ser un número formado por uno o varios dígitos.",
            'javax.faces.converter.ShortConverter.SHORT_detail': "{2}: '{0}' debe ser un número entre -32768 y 32767 Ejemplo: {1}",
            'javax.faces.converter.BooleanConverter.BOOLEAN': "{1}: '{0}' debe ser 'true' o 'false'",
            'javax.faces.converter.BooleanConverter.BOOLEAN_detail': "{1}: '{0}' debe ser 'true' o 'false'.  Cualquier valor diferente a 'true' se evaluará como 'false'.",
            'javax.faces.validator.LongRangeValidator.MAXIMUM': "{1}: Error de validación: el valor es mayor que el máximo permitido de '{0}'",
            'javax.faces.validator.LongRangeValidator.MINIMUM': "{1}: Error de validación: el valor es inferior que el mínimo permitido de '{0}'",
            'javax.faces.validator.LongRangeValidator.NOT_IN_RANGE': '{2}: Error de validación: el atributo especificado no está entre los valores esperados {0} y {1}.',
            'javax.faces.validator.LongRangeValidator.TYPE': '{0}: Error de validación: el valor no tiene el tipo correcto.',
            'javax.faces.validator.DoubleRangeValidator.MAXIMUM': "{1}: Error de validación: el valor es mayor que el máximo permitido de '{0}'",
            'javax.faces.validator.DoubleRangeValidator.MINIMUM': "{1}: Error de validación: el valor es inferior que el mínimo permitido de '{0}'",
            'javax.faces.validator.DoubleRangeValidator.NOT_IN_RANGE': '{2}: Error de validación: el atributo especificado no está entre los valores esperados {0} y {1}',
            'javax.faces.validator.DoubleRangeValidator.TYPE': '{0}: Error de validación: el valor no tiene el tipo correcto.',
            'javax.faces.converter.FloatConverter.FLOAT': "{2}: '{0}' debe ser un número formado por uno o varios dígitos.",
            'javax.faces.converter.FloatConverter.FLOAT_detail': "{2}: '{0}' debe ser un número entre 1.4E-45 y 3.4028235E38  Ejemplo: {1}",
            'javax.faces.converter.DateTimeConverter.DATE': "{2}: '{0}' no se ha podido reconocer como fecha.",
            'javax.faces.converter.DateTimeConverter.DATE_detail': "{2}: '{0}' no se ha podido reconocer como fecha. Ejemplo: {1}",
            'javax.faces.converter.DateTimeConverter.TIME': "{2}: '{0}' no se ha podido reconocer como hora.",
            'javax.faces.converter.DateTimeConverter.TIME_detail': "{2}: '{0}' no se ha podido reconocer como hora. Ejemplo: {1}",
            'javax.faces.converter.DateTimeConverter.DATETIME': "{2}: '{0}' no se ha podido reconocer como fecha y hora.",
            'javax.faces.converter.DateTimeConverter.DATETIME_detail': "{2}: '{0}' no se ha podido reconocer como fecha y hora. Ejemplo: {1}",
            'javax.faces.converter.DateTimeConverter.PATTERN_TYPE': "{1}: debe especificarse el atributo 'pattern' o 'type' para convertir el valor '{0}'",
            'javax.faces.converter.NumberConverter.CURRENCY': "{2}: '{0}' no se ha podido reconocer como un valor de divisa.",
            'javax.faces.converter.NumberConverter.CURRENCY_detail': "{2}: '{0}' no se ha podido reconocer como un valor de divisa. Ejemplo: {1}",
            'javax.faces.converter.NumberConverter.PERCENT': "{2}: '{0}' no se ha podido reconocer como porcentaje.",
            'javax.faces.converter.NumberConverter.PERCENT_detail': "{2}: '{0}' no se ha podido reconocer como porcentaje. Ejemplo: {1}",
            'javax.faces.converter.NumberConverter.NUMBER': "{2}: '{0}' no se ha podido reconocer como fecha.",
            'javax.faces.converter.NumberConverter.NUMBER_detail': "{2}: '{0}' no es un número. Ejemplo: {1}",
            'javax.faces.converter.NumberConverter.PATTERN': "{2}: '{0}' no es un patrón numérico.",
            'javax.faces.converter.NumberConverter.PATTERN_detail': "{2}: '{0}' no es un patrón numérico. Ejemplo: {1}",
            'javax.faces.validator.LengthValidator.MINIMUM': "{1}: Error de validación: el largo es inferior que el mínimo permitido de '{0}'",
            'javax.faces.validator.LengthValidator.MAXIMUM': "{1}: Error de validación: el largo es mayor que el máximo permitido de '{0}'",
            'javax.faces.validator.RegexValidator.PATTERN_NOT_SET': 'Se debe establecer el patrón de Regex.',
            'javax.faces.validator.RegexValidator.PATTERN_NOT_SET_detail': 'El patrón Regex debe establecerse en un valor no vacío.',
            'javax.faces.validator.RegexValidator.NOT_MATCHED': 'El patrón Regex no coincide',
            'javax.faces.validator.RegexValidator.NOT_MATCHED_detail': "El patrón Regex de '{0}' no coincide",
            'javax.faces.validator.RegexValidator.MATCH_EXCEPTION': 'Error en expresión regular.',
            'javax.faces.validator.RegexValidator.MATCH_EXCEPTION_detail': "Error en expresión regular, '{0}'",
            //optional for bean validation integration in client side validation
            'javax.faces.validator.BeanValidator.MESSAGE': '{0}',
            'javax.validation.constraints.AssertFalse.message': 'debe ser falso',
            'javax.validation.constraints.AssertTrue.message': 'debe ser verdadero',
            'javax.validation.constraints.DecimalMax.message': 'debe ser menor que o igual a {0}',
            'javax.validation.constraints.DecimalMin.message': 'debe ser mayor que o igual a {0}',
            'javax.validation.constraints.Digits.message': 'valor numérico fuera de los límites (se esperaba (<{0} digitos>.<{1} digitos>)',
            'javax.validation.constraints.Future.message': 'debe ser una fecha futura',
            'javax.validation.constraints.Max.message': 'debe ser menor que o igual a {0}',
            'javax.validation.constraints.Min.message': 'debe ser mayor que o igual a {0}',
            'javax.validation.constraints.NotNull.message': 'no debe ser nulo',
            'javax.validation.constraints.Null.message': 'debe ser nulo',
            'javax.validation.constraints.Past.message': 'debe ser una fecha pasada',
            'javax.validation.constraints.Pattern.message': 'debe coincidir con "{0}"',
            'javax.validation.constraints.Size.message': 'el tamaño debe estar entre {0} y {1}',
        },
    },
});
app.use(ConfirmationService);
app.use(ToastService);
app.use(router);
app.use(print);
app.use();

app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);
app.directive('code', CodeHighlight);
app.directive('badge', BadgeDirective);
app.directive('styleclass', StyleClass);

app.component('GoogleMap', GoogleMap);
app.component('Marker', Marker);
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('AutoComplete', AutoComplete);
app.component('Avatar', Avatar);
app.component('AvatarGroup', AvatarGroup);
app.component('Badge', Badge);
app.component('Breadcrumb', Breadcrumb);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Card', Card);
app.component('Carousel', Carousel);
app.component('Chart', Chart);
app.component('Checkbox', Checkbox);
app.component('Chip', Chip);
app.component('Chips', Chips);
app.component('ColorPicker', ColorPicker);
app.component('Column', Column);
app.component('ConfirmDialog', ConfirmDialog);
app.component('ConfirmPopup', ConfirmPopup);
app.component('ContextMenu', ContextMenu);
app.component('DataTable', DataTable);
app.component('DataView', DataView);
app.component('DataViewLayoutOptions', DataViewLayoutOptions);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('Fieldset', Fieldset);
app.component('FileUpload', FileUpload);
app.component('Image', Image);
app.component('InlineMessage', InlineMessage);
app.component('Inplace', Inplace);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('IconField', IconField);
app.component('InputIcon', InputIcon);
app.component('Galleria', Galleria);
app.component('Knob', Knob);
app.component('Listbox', Listbox);
app.component('MegaMenu', MegaMenu);
app.component('Menu', Menu);
app.component('Menubar', Menubar);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('OrderList', OrderList);
app.component('OrganizationChart', OrganizationChart);
app.component('OverlayPanel', OverlayPanel);
app.component('Paginator', Paginator);
app.component('Panel', Panel);
app.component('PanelMenu', PanelMenu);
app.component('Password', Password);
app.component('PickList', PickList);
app.component('ProgressBar', ProgressBar);
app.component('RadioButton', RadioButton);
app.component('Rating', Rating);
app.component('SelectButton', SelectButton);
app.component('ScrollPanel', ScrollPanel);
app.component('ScrollTop', ScrollTop);
app.component('Slider', Slider);
app.component('Sidebar', Sidebar);
app.component('Skeleton', Skeleton);
app.component('SplitButton', SplitButton);
app.component('Splitter', Splitter);
app.component('SplitterPanel', SplitterPanel);
app.component('Steps', Steps);
app.component('TabMenu', TabMenu);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Tag', Tag);
app.component('Textarea', Textarea);
app.component('TieredMenu', TieredMenu);
app.component('Timeline', Timeline);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('ToggleButton', ToggleButton);
app.component('Tree', Tree);
app.component('TreeSelect', TreeSelect);
app.component('TreeTable', TreeTable);
app.component('TriStateCheckbox', TriStateCheckbox);

app.component('BlockViewer', BlockViewer);

app.mount('#app');