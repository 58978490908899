import { createRouter, createWebHistory } from 'vue-router';
import { routesReportesAsociados } from '@/components/Asociados/Reportes/Routes/index.js';
import App from './App.vue';

const _TITLE = ' - Acoasmi El Roble';

const routes = [{
    path: '/',
    name: 'app',
    beforeEnter: verifyLoggedUser,
    component: App,
    children: [
        {
            path: '',
            name: 'home',
            component: () =>
                import('@/views/HomeView.vue'),
            meta: { title: 'Home' + _TITLE },
        },
        {
            path: 'perfil',
            name: 'profile',
            component: () =>
                import('@/views/General/ProfileView.vue'),
            meta: { title: 'Perfil' + _TITLE },
        },
        {
            path: 'usuarios',
            name: 'users',
            component: () =>
                import('@/views/UsersAndPermissions/UsersView.vue'),
            meta: { title: 'Usuarios' + _TITLE },
        },
        {
            path: 'usuarios/editar/:id',
            name: 'editUser',
            component: () =>
                import('@/views/UsersAndPermissions/EditUserView.vue'),
            meta: { title: 'Editar Usuario' + _TITLE },
        },
        {
            path: 'usuarios/crear',
            name: 'createUser',
            component: () =>
                import('@/views/UsersAndPermissions/CreateUserView.vue'),
            meta: { title: 'Crear usuario' + _TITLE },
        },
        {
            path: 'usuarios/permisos',
            name: 'user-permissions',
            component: () =>
                import('@/views/UsersAndPermissions/UsersPermissionsView.vue'),
            meta: { title: 'Permisos de usuarios' + _TITLE },
        },
        {
            path: 'usuarios/menu',
            name: 'menu-permissions',
            component: () =>
                import('@/views/UsersAndPermissions/MenuPermissionsView.vue'),
            meta: { title: 'Permisos de menu de usuario' + _TITLE },
        },
        {
            path: 'asociados',
            name: 'coo_asociados',
            component: () =>
                import('@/views/Asociados/CooAsociadosView.vue'),
            meta: { title: 'Asociados' + _TITLE },
        },
        {
            path: 'asociados/:id',
            name: 'viewCooAsociados',
            component: () =>
                import('@/views/Asociados/SingleCooAsociadosView.vue'),
            meta: { title: 'Asociados' + _TITLE },
        },
        {
            path: 'asociados/crear',
            name: 'createCooAsociados',
            component: () =>
                import('@/views/Asociados/CrearCooAsociadosView.vue'),
            meta: { title: 'Crear asociados' + _TITLE },
        },
        {
            path: 'asociados/:id/editar',
            name: 'updateCooAsociados',
            component: () =>
                import('@/views/Asociados/EditarCooAsociadosView.vue'),
            meta: { title: 'Editar asociados' + _TITLE },
        },
        {
            path: 'asociados/:id/acciones',
            name: 'accionesCooAsociados',
            component: () =>
                import('@/views/Asociados/AccionesCooAsociadosView.vue'),
            meta: { title: 'Acciones con asociado' + _TITLE },
        },
        {
            path: 'asociados/solicitud-retiro/',
            name: 'adminSolicitudRetiroCooAsociados',
            component: () =>
                import('@/views/Asociados/AdminSolicitudRetiroCooAsociadosView.vue'),
            meta: { title: 'Administrar solicitudes de retiro' + _TITLE },
        },
        {
            path: 'asociados/solicitud-retiro/crear',
            name: 'createSolicitudRetiroCooAsociados',
            component: () =>
                import('@/views/Asociados/CrearSolicitudRetiroCooAsociadosView.vue'),
            meta: { title: 'Crear solicitud de retiro' + _TITLE },
        },
        {
            path: 'asociados/usuarios-remesas/',
            name: 'createUsuariosRemesas',
            component: () =>
                import('@/views/Asociados/AdminCooUsuariosRemesasView.vue'),
            meta: { title: 'Administrar usuarios de remesas' + _TITLE },
        },
        ...routesReportesAsociados,
        {
            path: 'profesiones',
            name: 'coo_profesiones',
            component: () =>
                import('@/views/General/AdministrarCooProfesionesView.vue'),
            meta: { title: 'Profesiones' + _TITLE },
        },
        {
            path: 'actividades/rubros',
            name: 'coo_rubrosactividades',
            component: () =>
                import(
                    '@/views/General/Actividades/AdministrarCooRubrosActividadesView.vue'
                ),
            meta: { title: 'Rubros de actividades' + _TITLE },
        },
        {
            path: 'actividades',
            name: 'coo_actividades',
            component: () =>
                import(
                    '@/views/General/Actividades/AdministrarCooActividadesView.vue'
                ),
            meta: { title: 'Actividades' + _TITLE },
        },
        {
            path: 'aportaciones/liquidar',
            name: 'createCooLiquidacionAportaciones',
            component: () =>
                import(
                    '@/views/Aportaciones/CooLiquidacionAportaciones/CrearCooLiquidacionAportacionesView.vue'
                ),
            meta: { title: 'Liquidar aportaciones' + _TITLE },
        },
        {
            path: 'aportaciones/eliminar',
            name: 'deleteCooAportaciones',
            component: () =>
                import('@/views/Aportaciones/EliminarCooAportacionesView.vue'),
            meta: { title: 'Eliminar aportaciones' + _TITLE },
        },
        {
            path: 'aportaciones/estado-cuentas',
            name: 'estadoDeCuentasCooAportaciones',
            component: () =>
                import(
                    '@/views/Aportaciones/Otros/EstadoDeCuentasCooAportaciones.vue'
                ),
            meta: { title: 'Estado de cuentas aportaciones' + _TITLE },
        },
        {
            path: 'aportaciones/rango-fechas',
            name: 'rangoDeFechasCooAportaciones',
            component: () =>
                import('@/views/Aportaciones/Otros/RangoDeFechasCooAportaciones.vue'),
            meta: {
                title: 'Movimiento por rango de fecha en aportaciones' + _TITLE,
            },
        },
        {
            path: 'aportaciones/saldos-asociados',
            name: 'SaldosPorAsociadosCooAportaciones',
            component: () =>
                import(
                    '@/views/Aportaciones/Otros/SaldosPorAsociadosCooAportaciones.vue'
                ),
            meta: {
                title: 'Saldos totales por asociados en aportaciones' + _TITLE,
            },
        },
        {
            path: 'aportaciones/total-genero',
            name: 'TotalPorGeneroCooAportaciones',
            component: () =>
                import(
                    '@/views/Aportaciones/Otros/TotalPorGeneroCooAportaciones.vue'
                ),
            meta: { title: 'Total por género en aportaciones' + _TITLE },
        },
        {
            path: 'aportaciones/reparto-dividendos',
            name: 'RepartoDeDividendos',
            component: () =>
                import(
                    '@/views/Aportaciones/Otros/RepartoDeDividendosCooAportaciones.vue'
                ),
            meta: { title: 'Reparto de dividendos en aportaciones' + _TITLE },
        },
        {
            path: 'aportaciones/reparto-dividendos-excedentes',
            name: 'RepartoDeDividendosConExcedentes',
            component: () =>
                import(
                    '@/views/Aportaciones/Otros/RepartoDeDividendosConExcedentesCooAportaciones.vue'
                ),
            meta: {
                title: 'Reparto de dividendos con excedentes en aportaciones' + _TITLE,
            },
        },
        {
            path: 'aportaciones/f915',
            name: 'ArchivoF915',
            component: () =>
                import(
                    '@/views/Aportaciones/Otros/GenerarArchivoF915CooAportaciones.vue'
                ),
            meta: {
                title: 'Generar archivo F915 en aportaciones' + _TITLE,
            },
        },
        {
            path: 'ahorros/tipos-cuentas-ahorro',
            name: 'AdministrarCooTiposCuentaAhorro',
            component: () =>
                import('@/views/Ahorros/CooTiposCuentaAhorroView.vue'),
            meta: {
                title: 'Administración de tipos de cuentas de ahorro' + _TITLE,
            },
        },
        {
            path: 'tipos-prestamos',
            name: 'AdministrarCooTiposPrestamo',
            component: () =>
                import('@/views/Prestamos/CooTiposPrestamos.vue'),
            meta: {
                title: 'Administración de tipos de prestamos' + _TITLE,
            },
        },
        {
            path: 'lineas-credito',
            name: 'AdministrarCooLineasCredito',
            component: () =>
                import('@/views/Prestamos/AdminCooLineasCreditoView.vue'),
            meta: {
                title: 'Administración de lineas de credito' + _TITLE,
            },
        },
        {
            path: 'tecnicos',
            name: 'AdministrarCooTecnicos',
            component: () =>
                import('@/views/Prestamos/AdminCooTecnicosView.vue'),
            meta: {
                title: 'Administración de tecnicos' + _TITLE,
            },
        },
        {
            path: 'fuentes',
            name: 'AdministrarCooFuentes',
            component: () =>
                import('@/views/Prestamos/AdminCooFuentesView.vue'),
            meta: {
                title: 'Administración de fuentes' + _TITLE,
            },
        },
        {
            path: 'tipos-calculo',
            name: 'AdministrarCooTiposCalculo',
            component: () =>
                import('@/views/Prestamos/AdminCooTiposCalculoView.vue'),
            meta: {
                title: 'Administración de tipos de calculo' + _TITLE,
            },
        },
        {
            path: 'tipos-trabajo',
            name: 'AdministrarCooTiposTrabajo',
            component: () =>
                import('@/views/Prestamos/AdminCooTiposTrabajoView.vue'),
            meta: {
                title: 'Administración de tipos de trabajo' + _TITLE,
            },
        },
        {
            path: 'configuraciones-prestamos',
            name: 'AdministrarCooConfiguracionesPrestamos',
            component: () =>
                import('@/views/Prestamos/AdminCooConfiguracionesPrestamosView.vue'),
            meta: {
                title: 'Administración de configuraciones de prestamos' + _TITLE,
            },
        },
        {
            path: 'tipos-empleo',
            name: 'AdministrarCooTiposEmpleo',
            component: () =>
                import('@/views/Prestamos/AdminCooTiposEmpleoView.vue'),
            meta: {
                title: 'Administración de tipos de empleo' + _TITLE,
            },
        },
        {
            path: 'tipos-garantia',
            name: 'AdministrarCooTiposGarantia',
            component: () =>
                import('@/views/Prestamos/AdminCooTiposGarantiaView.vue'),
            meta: {
                title: 'Administración de tipos de garantia' + _TITLE,
            },
        },
        {
            path: 'tipos-cartas',
            name: 'AdministrarCooTiposCarta',
            component: () =>
                import('@/views/Prestamos/AdminCooTiposCartaView.vue'),
            meta: {
                title: 'Administración de tipos de cartas' + _TITLE,
            },
        },
        {
            path: 'solicitudes-prestamos',
            name: 'AdministrarCooSolicitudesPrestamo',
            component: () =>
                import('@/views/Prestamos/AdminCooSolicitudesPrestamoView.vue'),
            meta: {
                title: 'Administración de solicitudes de prestamos' + _TITLE,
            },
        },
        {
            path: 'solicitudes-prestamos/crear',
            name: 'CrearCooSolicitudesPrestamo',
            component: () =>
                import('@/components/Prestamos/CooSolicitudesPrestamo/CrearCooSolicitudesPrestamoComponent.vue'),
            meta: {
                title: 'Crear solicitud de prestamo' + _TITLE,
            },
        },
        {
            path: 'solicitudes-prestamos/editar/:IdSolicitud',
            name: 'ActualizarCooSolicitudesPrestamo',
            component: () =>
                import('@/components/Prestamos/CooSolicitudesPrestamo/SolicitudPrestamoComponent.vue'),
            meta: {
                title: 'Actualizar solicitud de prestamo' + _TITLE,
            },
        },
        {
            path: 'solicitudes-prestamos/resolucion/:IdSolicitud',
            name: 'ResolucionCooSolicitudesPrestamo',
            component: () =>
                import('@/components/Prestamos/CooSolicitudesPrestamo/ResolucionPrestamoComponent.vue'),
            meta: {
                title: 'Dar resolución a la solicitud de prestamo' + _TITLE,
            },
        },
        {
            path: 'calculadora-prestamos',
            name: 'CalculadoraPrestamos',
            component: () =>
                import('@/AppCalculator.vue'),
            meta: {
                title: 'Calculadora de préstamos' + _TITLE,
            },
        },
        {
            path: 'seguimientos-cobros/:IdPrestamo',
            name: 'AdministrarCooSeguimientoCobtros',
            component: () =>
                import('@/views/Prestamos/AdminCooSeguimientoCobrosView.vue'),
            meta: {
                title: 'Administración de acuerdos de cliente' + _TITLE,
            },
        },
        {
            path: 'calculo-cuotas',
            name: 'AdministrarCooCalculoCuotas',
            component: () =>
                import('@/views/Prestamos/AdminCooCalculoCuotasView.vue'),
            meta: {
                title: 'Administración de calculo de cuotas' + _TITLE,
            },
        },
        {
            path: 'estados-cuenta',
            name: 'AdministrarCooEstadosCuenta',
            component: () =>
                import('@/views/Prestamos/AdminCooEstadosCuentaView.vue'),
            meta: {
                title: 'Administración de estados de cuentas' + _TITLE,
            },
        },
        {
            path: 'deducciones-prestamo',
            name: 'AdministrarCooDeduccionesPrestamo',
            component: () =>
                import('@/views/Prestamos/AdminCooDeduccionesPrestamoView.vue'),
            meta: {
                title: 'Administración de deducciones de prestamos' + _TITLE,
            },
        },
        {
            path: 'solicitud-fondos',
            name: 'SolicitudFondos',
            component: () =>
                import('@/views/Prestamos/SolicitudFondosView.vue'),
            meta: {
                title: 'SolicitudFondos' + _TITLE,
            },
        },
        {
            path: 'documentacion-asociado-prestamo',
            name: 'DocumentacionAsociado',
            component: () =>
                import('@/views/Prestamos/AdminDocumentacionAsociadoView.vue'),
            meta: {
                title: 'DocumentacionAsociado' + _TITLE,
            },
        },
        {
            path: 'anular-solicitud-fondo',
            name: 'AnularSolicitudFondo',
            component: () =>
                import('@/views/Prestamos/AnularSolicitudFondoView.vue'),
            meta: {
                title: 'AnularSolicitudFondo' + _TITLE,
            },
        },
        {
            path: 'documentos-prestamos',
            name: 'DocumentosPrestamosLegales',
            component: () =>
                import('@/views/Prestamos/DocumentosPrestamosLegalesView.vue'),
            meta: {
                title: 'DocumentosPrestamosLegales' + _TITLE,
            },
        },
        {
            path: 'ahorros/cuentas-ahorro',
            name: 'AdministrarCooCuentasAhorro',
            component: () =>
                import('@/views/Ahorros/CooCuentasAhorroView.vue'),
            meta: {
                title: 'Administración de cuentas de ahorro' + _TITLE,
            },
        },
        {
            path: 'ahorros/cambiar-tasa-interes-ahorro',
            name: 'CambiarTasaInteres',
            component: () =>
                import('@/views/Ahorros/CambiarTasaView.vue'),
            meta: {
                title: 'Cambiar tasa de cuentas de ahorro' + _TITLE,
            },
        },
        {
            path: 'ahorros/cuentas-ahorro/crear',
            name: 'CrearCooCuentasAhorro',
            component: () =>
                import(
                    '@/views/Ahorros/CrudCooCuentasAhorro/CrearCooCuentasAhorroView.vue'
                ),
            meta: {
                title: 'Creación de cuenta de ahorro' + _TITLE,
            },
        },
        {
            path: 'ahorros/cuentas-ahorro/:id/editar',
            name: 'EditarCooCuentasAhorro',
            component: () =>
                import(
                    '@/views/Ahorros/CrudCooCuentasAhorro/EditarCooCuentasAhorroView.vue'
                ),
            meta: {
                title: 'Edicion de cuenta de ahorro' + _TITLE,
            },
        },

        {
            path: 'ahorros/cuentas-ahorro/:id/acciones',
            name: 'AccionesCooCuentasAhorro',
            component: () =>
                import(
                    '@/views/Ahorros/CrudCooCuentasAhorro/AccionesCooCuentasAhorroView.vue'
                ),
            meta: {
                title: 'Acciones de cuenta de ahorro' + _TITLE,
            },
        },

        {
            path: 'ahorros/anular-deposito-retiro',
            name: 'EliminarDepositoRetiro',
            component: () =>
                import(
                    '@/views/Ahorros/EliminarDepositosRetiroCooCuentasAhorroView.vue'
                ),
            meta: {
                title: 'Eliminar depósito y retiro de cuenta de ahorro' + _TITLE,
            },
        },
        {
            path: 'ahorros/pignoracion-cuentas',
            name: 'PignoraciondeCuentas',
            component: () =>
                import(
                    '@/views/Ahorros/PignoraciondeCuentasView.vue'
                ),
            meta: {
                title: 'Pignoración de Cuentas' + _TITLE,
            },
        },
        {
            path: 'ahorros/reportes',
            name: 'ReportesAhorros',
            component: () =>
                import(
                    '@/views/Ahorros/ReportesView.vue'
                ),
            meta: {
                title: 'Reportes de ahorros' + _TITLE,
            },
        },
        {
            path: 'ahorros/reportes/estado-cuentas',
            name: 'ReporteEstadoCuentas',
            component: () =>
                import(
                    '@/views/Ahorros/Reportes/EstadoCuentaAhorroView.vue'
                ),
            meta: {
                title: 'Reporte de estado de cuentas' + _TITLE,
            },
        },
        {
            path: 'ahorros/reportes/listado-general',
            name: 'ReporteListadoGeneral',
            component: () =>
                import(
                    '@/views/Ahorros/Reportes/ListadoGeneralAhorroView.vue'
                ),
            meta: {
                title: 'Reporte de listado general' + _TITLE,
            },
        },
        {
            path: 'ahorros/reportes/listado-movimientos',
            name: 'ReportesListadoMovimientos',
            component: () =>
                import(
                    '@/views/Ahorros/Reportes/ListadoMovimientosAhorroView.vue'
                ),
            meta: {
                title: 'Reporte de listado de movimientos' + _TITLE,
            },
        },
        {
            path: 'ahorros/reportes/totales-movimientos',
            name: 'ReportesTotalesMovimientos',
            component: () =>
                import(
                    '@/views/Ahorros/Reportes/TotalesMovimientoAhorroView.vue'
                ),
            meta: {
                title: 'Reporte de totales movimientos' + _TITLE,
            },
        },
        {
            path: 'ahorros/reportes/reporte-intereses',
            name: 'ReportesIntereses',
            component: () =>
                import(
                    '@/views/Ahorros/Reportes/ReporteInteresesAhorroView.vue'
                ),
            meta: {
                title: 'Reporte de intereses' + _TITLE,
            },
        },
        {
            path: 'ahorros/reportes/deposito-plazo-por-vencer',
            name: 'ReportesDepositoPlazoPorVencer',
            component: () =>
                import(
                    '@/views/Ahorros/Reportes/DepositoPlazoVencerAhorroView.vue'
                ),
            meta: {
                title: 'Reporte de depósitos a plazo por vencer' + _TITLE,
            },
        },
        {
            path: 'ahorros/reportes/listado-depositos-a-plazo',
            name: 'ReportesListadoDepositosPlazo',
            component: () =>
                import(
                    '@/views/Ahorros/Reportes/ListadoDepositoPlazoAhorroView.vue'
                ),
            meta: {
                title: 'Reporte de listado de depósitos a plazo' + _TITLE,
            },
        },
        {
            path: 'ahorros/reportes/reporte-provision',
            name: 'ReportesProvision',
            component: () =>
                import(
                    '@/views/Ahorros/Reportes/ReporteProvisionAhorroView.vue'
                ),
            meta: {
                title: 'Reporte de provision' + _TITLE,
            },
        },
        {
            path: 'ahorros/reportes/reporte-capitalizacion',
            name: 'ReportesCapitalizacion',
            component: () =>
                import(
                    '@/views/Ahorros/Reportes/ReporteCapitalizacionAhorroView.vue'
                ),
            meta: {
                title: 'Reporte de capitalización' + _TITLE,
            },
        },
        {
            path: 'ahorros/reportes/listado-cuentas-por-tipo',
            name: 'ReportesListadoCuentasTipo',
            component: () =>
                import(
                    '@/views/Ahorros/Reportes/ListadoCuentasTipoAhorroView.vue'
                ),
            meta: {
                title: 'Reporte de listado de cuentas por tipo' + _TITLE,
            },
        },
        {
            path: 'ahorros/reportes/listado-cuentas-mancomunadas',
            name: 'ReportesCuentasMancomunadas',
            component: () =>
                import(
                    '@/views/Ahorros/Reportes/ListadoCuentasMancomunadasAhorroView.vue'
                ),
            meta: {
                title: 'Reporte de cuentas mancomunadas' + _TITLE,
            },
        },
        /*******************************************************************
         *******************************************************************
         ******************** ROUTES OF CAJA *******************************
         *******************************************************************
        ********************************************************************
        */

        {
            path: 'caja',
            name: 'Caja',
            component: () =>
                import(
                    '@/views/Caja/CajaMainView.vue'
                ),
            meta: {
                title: 'Caja' + _TITLE,
            },
        },
        {
            path: 'caja/ingresos',
            name: 'MainIngresosCaja',
            component: () =>
                import(
                    '@/views/Caja/Ingresos/MainIngresosView.vue'
                ),
            meta: {
                title: 'MainIngresosCaja' + _TITLE,
            },
        },
        {
            path: 'caja/egresos',
            name: 'MainEgresosCaja',
            component: () =>
                import(
                    '@/views/Caja/Egresos/EgresosMainView.vue'
                ),
            meta: {
                title: 'MainEgresosCaja' + _TITLE,
            },
        },
        // CAJA - INGRESOS++++++++++++++++++++++++++++++++++++++++++++++++++
        {
            path: 'caja/ingresos/afiliaciones',
            name: 'Afiliaciones',
            component: () =>
                import(
                    '@/views/Caja/Ingresos/AfiliacionesView.vue'
                ),
            meta: {
                title: 'Afiliaciones' + _TITLE,
            },
        },
        {
            path: 'caja/ingresos/ingresos-aportaciones',
            name: 'IngresosAportaciones',
            component: () =>
                import(
                    '@/views/Caja/Ingresos/IngresosDeAportacionesView.vue'
                ),
            meta: {
                title: 'IngresosAportaciones' + _TITLE,
            },
        },
        {
            path: 'caja/ingresos/pagos',
            name: 'Pagos',
            component: () =>
                import(
                    '@/views/Caja/Ingresos/PagosView.vue'
                ),
            meta: {
                title: 'Pagos' + _TITLE,
            },
        },
        {
            path: 'caja/ingresos/depositos',
            name: 'Depositos',
            component: () =>
                import(
                    '@/views/Caja/Ingresos/DepositosView.vue'
                ),
            meta: {
                title: 'Depositos' + _TITLE,
            },
        },
        {
            path: 'caja/ingresos/otros-ingresos',
            name: 'OtrosIngresos',
            component: () =>
                import(
                    '@/views/Caja/Ingresos/OtrosIngresosView.vue'
                ),
            meta: {
                title: 'OtrosIngresos' + _TITLE,
            },
        },
        // CAJA - EGRESOS+++++++++++++++++++++++++++++++++++++++++++++++++++
        {
            path: 'caja/egresos/retiros',
            name: 'Retiros',
            component: () =>
                import(
                    '@/views/Caja/Egresos/RetirosView.vue'
                ),
            meta: {
                title: 'Retiros' + _TITLE,
            },
        },
        {
            path: 'caja/egresos/retiros',
            name: 'Retiros',
            component: () =>
                import(
                    '@/views/Caja/Egresos/RetirosView.vue'
                ),
            meta: {
                title: 'Retiros' + _TITLE,
            },
        },
        {
            path: 'caja/egresos/cancelacion-cuenta',
            name: 'CancelacionCuenta',
            component: () =>
                import(
                    '@/views/Caja/Egresos/CancelacionCuentaView.vue'
                ),
            meta: {
                title: 'CancelacionCuenta' + _TITLE,
            },
        },
        {
            path: 'caja/egresos/retiro-aportaciones',
            name: 'RetirosAportaciones',
            component: () =>
                import(
                    '@/views/Caja/Egresos/RetiroAportacionesView.vue'
                ),
            meta: {
                title: 'RetirosAportaciones' + _TITLE,
            },
        },
        {
            path: 'caja/egresos/otros-egresos',
            name: 'OtrosEgresos',
            component: () =>
                import(
                    '@/views/Caja/Egresos/OtrosEgresosView.vue'
                ),
            meta: {
                title: 'OtrosEgresos' + _TITLE,
            },
        },
        {
            path: 'caja/egresos/entregas-desembolso',
            name: 'EntregasDesembolso',
            component: () =>
                import(
                    '@/views/Caja/Egresos/EntregasDesembolsoView.vue'
                ),
            meta: {
                title: 'EntregasDesembolso' + _TITLE,
            },
        },
        // CONSULTAS CAJA
        {
            path: 'caja/consultas/estado-cuentas',
            name: 'EstadoCuentaCaja',
            component: () =>
                import(
                    '@/views/Caja/Consultas/EstadoCuentaCajaView.vue'
                ),
            meta: {
                title: 'EstadoCuentaCaja' + _TITLE,
            },
        },
        {
            path: 'caja/consultas/consulta-general',
            name: 'ConsultaGeneralCaja',
            component: () =>
                import(
                    '@/views/Caja/Consultas/ConsultaGeneralCajaView.vue'
                ),
            meta: {
                title: 'ConsultaGeneralCaja' + _TITLE,
            },
        },
        {
            path: 'caja/consultas/plan-pagos',
            name: 'PlanPagosCaja',
            component: () =>
                import(
                    '@/views/Caja/Consultas/PlanPagosCajaView.vue'
                ),
            meta: {
                title: 'PlanPagosCaja' + _TITLE,
            },
        },
        {
            path: 'caja/consultas/lista-cautelas',
            name: 'ListaCautelasCaja',
            component: () =>
                import(
                    '@/views/Caja/Consultas/ListaCautelasCajaView.vue'
                ),
            meta: {
                title: 'ListaCautelasCaja' + _TITLE,
            },
        },
        // OPERACIONES CAJA
        {
            path: 'caja/operaciones/usuario-remesa',
            name: 'UsuarioRemesaCaja',
            component: () =>
                import(
                    '@/views/Caja/Operaciones/UsuarioRemesaCajaView.vue'
                ),
            meta: {
                title: 'UsuarioRemesaCaja' + _TITLE,
            },
        },
        {
            path: 'caja/operaciones/actualizacion-libreta-aportaciones',
            name: 'ActualizacionLibretaAportaciones',
            component: () =>
                import(
                    '@/views/Caja/Operaciones/ActualizacionLibretaAportacionesCajaView.vue'
                ),
            meta: {
                title: 'ActualizacionLibretaAportaciones' + _TITLE,
            },
        },
        {
            path: 'caja/operaciones/actualizacion-libreta-ahorro',
            name: 'ActualizacionLibretaAhorro',
            component: () =>
                import(
                    '@/views/Caja/Operaciones/ActualizacionLibretaAhorroCajaView.vue'
                ),
            meta: {
                title: 'ActualizacionLibretaAhorro' + _TITLE,
            },
        },
        {
            path: 'caja/operaciones/reimprimir-pago',
            name: 'ReimprimirPago',
            component: () =>
                import(
                    '@/views/Caja/Operaciones/ReimprimirPagoCajaView.vue'
                ),
            meta: {
                title: 'ReimprimirPago' + _TITLE,
            },
        },
        {
            path: 'caja/operaciones/tickets-promocion',
            name: 'TicketsPromocionCaja',
            component: () =>
                import(
                    '@/views/Caja/Operaciones/TicketsPromocionCajaView.vue'
                ),
            meta: {
                title: 'TicketsPromocionCaja' + _TITLE,
            },
        },
        {
            path: 'caja/anulaciones/anular-otro-egreso',
            name: 'AnularCooOtroEgreso',
            component: () =>
                import(
                    '@/views/Caja/Anulaciones/AnularOtroEgresoView.vue'
                ),
            meta: {
                title: 'Anular egreso' + _TITLE,
            },
        },
        // APERTURA CAJA
        {
            path: 'caja/requisicion-efectivo/',
            name: 'AperturaCaja',
            component: () =>
                import(
                    '@/views/Caja/AperturaCaja/AperturaCajaView.vue'
                ),
            meta: {
                title: 'Apertura caja' + _TITLE,
            },
        },
        // CORTE CAJA
        {
            path: 'caja/remision-efectivo/',
            name: 'RemisionEfectivo',
            component: () =>
                import(
                    '@/views/Caja/AperturaCaja/CorteCajaView.vue'
                ),
            meta: {
                title: 'Remision efectivo' + _TITLE,
            },
        },
        // CORTE CAJA
        {
            path: 'caja/operaciones/corte-caja',
            name: 'CorteCaja',
            component: () =>
                import(
                    '@/views/Caja/Operaciones/CorteCajaView.vue'
                ),
            meta: {
                title: 'Corte caja' + _TITLE,
            },
        },

        //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        // CONSULTA Y REPORTES++++++++++++++++++++++++++++++++++++++++++++++
        //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

        //INFORMES
        {
            path: 'consultas-reportes/informes',
            name: 'Informes',
            component: () =>
                import(
                    '@/views/Consulta-Reportes/Informes/InformesView.vue'
                ),
            meta: {
                title: 'Informes' + _TITLE,
            },
        },

        //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        // OFICIALIA++++++++++++++++++++++++++++++++++++++++++++++
        //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

        //RIESGOS
        {
            path: 'oficialia/riesgos',
            name: 'Riesgos',
            component: () =>
                import(
                    '@/views/Oficialia/Riesgos/RiesgosView.vue'
                ),
            meta: {
                title: 'Riesgos' + _TITLE,
            },
        },

        //AGREGAR PONDERACIONES RIESGOS
        {
            path: 'oficialia/riesgos/ponderaciones-riesgo/agregar',
            name: 'AgregarPonderacionRiesgo',
            component: () =>
                import(
                    '@/components/Oficialia/Riesgos/CrearNuevaPonderacionComponent.vue'
                ),
            meta: {
                title: 'AgregarPonderacionRiesgo' + _TITLE,
            },
        },

        //RIESGOS
        {
            path: 'oficialia/riesgos/ponderaciones-riesgo/:IdPonderacion/editar',
            name: 'EditarPonderacionesRiesgo',
            component: () =>
                import(
                    '@/components/Oficialia/Riesgos/EditarPonderacionComponent.vue'
                ),
            meta: {
                title: 'EditarPonderacionesRiesgo' + _TITLE,
            },
        },
        //RELACIONAR PARENTESCOS
        {
            path: 'oficialia/relacionar-parentescos',
            name: 'RelacionarParentescos',
            component: () =>
                import(
                    '@/views/Oficialia/RelacionarParentescos/RelacionarParentescoView.vue'
                ),
            meta: {
                title: 'RelacionarParentescos' + _TITLE,
            },
        },
        //CONSULTAS
        {
            path: 'oficialia/consultas',
            name: 'Consultas',
            component: () =>
                import(
                    '@/views/Oficialia/Consultas/ConsultasView.vue'
                ),
            meta: {
                title: 'Consultas' + _TITLE,
            },
        },

        //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        // OTROS PROCESOS+++++++++++++++++++++++++++++++++++++++++++++++++++
        //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

        //LISTA NEGRA INTERNA
        {
            path: 'otros-procesos/otros-procesos/lista-negra-interna',
            name: 'ListaNegraInterna',
            component: () =>
                import(
                    '@/views/OtrosProcesos/OtrosProcesos/ListaNegraInternaView.vue'
                ),
            meta: {
                title: 'ListaNegraInterna' + _TITLE,
            },
        },

        //LISTA PEPS
        {
            path: 'otros-procesos/otros-procesos/lista-peps',
            name: 'ListaPeps',
            component: () =>
                import(
                    '@/views/OtrosProcesos/OtrosProcesos/ListaPepsView.vue'
                ),
            meta: {
                title: 'ListaPeps' + _TITLE,
            },
        },

        //OFICIOS UIF
        {
            path: 'otros-procesos/otros-procesos/oficios-uif',
            name: 'OficiosUIF',
            component: () =>
                import(
                    '@/views/OtrosProcesos/OtrosProcesos/OficiosUIFView.vue'
                ),
            meta: {
                title: 'OficiosUIF' + _TITLE,
            },
        },
        {
            path: 'otros-procesos/otros-procesos/oficios-uif/agregar',
            name: 'AgregarOficioUIF',
            component: () =>
                import(
                    '@/components/OtrosProcesos/OficiosUIF/OficiosComponent.vue'
                ),
            meta: {
                title: 'AgregarOficioUIF' + _TITLE,
            },
        },
        {
            path: 'otros-procesos/otros-procesos/oficios-uif/:id_correlativo/editar',
            name: 'EditarOficioUIF',
            component: () =>
                import(
                    '@/components/OtrosProcesos/OficiosUIF/EditarOficiosComponent.vue'
                ),
            meta: {
                title: 'EditarOficioUIF' + _TITLE,
            },
        },

        //NUMERARIO
        {
            path: 'otros-procesos/numerario',
            name: 'Numerario',
            component: () =>
                import(
                    '@/views/OtrosProcesos/Numerario/NumerarioView.vue'
                ),
            meta: {
                title: 'Numerario' + _TITLE,
            },
        },

        //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        // ADMINISTRACION+++++++++++++++++++++++++++++++++++++++++++++++++++
        //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

        //VALOR FOE
        {
            path: 'administracion/valor-foe',
            name: 'ValorFoe',
            component: () =>
                import(
                    '@/views/Administracion/ValorFoe/ValorFoeView.vue'
                ),
            meta: {
                title: 'ValorFoe' + _TITLE,
            },
        },
        // ALERTAS
        {
            path: 'administracion/definir-alertas',
            name: 'DefinirAlertas',
            component: () =>
                import(
                    '@/views/Administracion/Alertas/AlertaView.vue'
                ),
            meta: {
                title: 'DefinirAlertas' + _TITLE,
            },
        },
        // CIERRE DEL DIA
        {
            path: 'administracion/cierre-dia',
            name: 'CierreDia',
            component: () =>
                import(
                    '@/views/Administracion/CierreDia/CierreDiaView.vue'
                ),
            meta: {
                title: 'CierreDia' + _TITLE,
            },
        },

        //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        // FINANCIERO+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++


        // ***********************CONTABILIDAD**********************************
        // PARTIDAS
        {
            path: 'financiero/contabilidad/partidas',
            name: 'Partidas',
            component: () =>
                import(
                    '@/views/Financiero/Contabilidad/PartidasView.vue'
                ),
            meta: {
                title: 'Partidas' + _TITLE,
            },
        },
        {
            path: 'financiero/contabilidad/partidas/agregar',
            name: 'AgregarPartida',
            component: () =>
                import(
                    '@/views/Financiero/Contabilidad/AgregarEditarPartidasView.vue'
                ),
            meta: {
                title: 'Agregar Partida' + _TITLE,
            },
        },
        {
            path: 'financiero/contabilidad/partidas/:id_partida/editar',
            name: 'EditarPartida',
            component: () =>
                import(
                    '@/views/Financiero/Contabilidad/AgregarEditarPartidasView.vue'
                ),
            meta: {
                title: 'Editar Partida' + _TITLE,
            },
        },
        // PROCESOS
        {
            path: 'financiero/contabilidad/procesos',
            name: 'ProcesosContabilidad',
            component: () =>
                import(
                    '@/views/Financiero/Contabilidad/ProcesosView.vue'
                ),
            meta: {
                title: 'ProcesosContabilidad' + _TITLE,
            },
        },
        // REPORTES ESTANDARES
        {
            path: 'financiero/contabilidad/reportes-estandares',
            name: 'ReportesEstandares',
            component: () =>
                import(
                    '@/views/Financiero/Contabilidad/ReportesEstandaresView.vue'
                ),
            meta: {
                title: 'ReportesEstandares' + _TITLE,
            },
        },
        // REPORTES FINANCIEROS
        {
            path: 'financiero/contabilidad/reportes-financieros',
            name: 'ReportesFinancieros',
            component: () =>
                import(
                    '@/views/Financiero/Contabilidad/ReportesFinancierosView.vue'
                ),
            meta: {
                title: 'ReportesFinancieros' + _TITLE,
            },
        },
        // ADMINISTRACION
        {
            path: 'financiero/contabilidad/administracion',
            name: 'AdministracionContabilidad',
            component: () =>
                import(
                    '@/views/Financiero/Contabilidad/AdministracionView.vue'
                ),
            meta: {
                title: 'AdministracionContabilidad' + _TITLE,
            },
        },

        // *****************************BANCOS**********************************
        // EMISION DE CHEQUES
        {
            path: 'financiero/bancos/emision-cheques',
            name: 'EmisionCheques',
            component: () =>
                import(
                    '@/views/Financiero/Bancos/EmisionChequesView.vue'
                ),
            meta: {
                title: 'EmisionCheques' + _TITLE,
            },
        },
        {
            path: 'financiero/bancos/emision-cheques/agregar',
            name: 'AgregarCheque',
            component: () =>
                import(
                    '@/views/Financiero/Bancos/AgregarEditarChequeView.vue'
                ),
            meta: {
                title: 'Agregar Cheque' + _TITLE,
            },
        },
        {
            path: 'financiero/bancos/emision-cheques/:idCheque/editar',
            name: 'EditarCheque',
            component: () =>
                import(
                    '@/views/Financiero/Bancos/AgregarEditarChequeView.vue'
                ),
            meta: {
                title: 'Editar Cheque' + _TITLE,
            },
        },
        // PROCESOS
        {
            path: 'financiero/bancos/procesos',
            name: 'ProcesosFinancieros',
            component: () =>
                import(
                    '@/views/Financiero/Bancos/ProcesosView.vue'
                ),
            meta: {
                title: 'ProcesosFinancieros' + _TITLE,
            },
        },
        // TRANSACCIONES BANCARIAS
        {
            path: 'financiero/bancos/procesos/agregar',
            name: 'AgregarTransaccionBancaria',
            component: () =>
                import(
                    '@/components/Financiero/Bancos/Procesos/DepositosRetirosTransferencias/DepositoRetiroTransferenciaComponent.vue'
                ),
            meta: {
                title: 'Agregar Transaccion Bancaria' + _TITLE,
            },
        },
        {
            path: 'financiero/bancos/procesos/:IdTransaccion/editar',
            name: 'EditarTransaccionBancaria',
            component: () =>
                import(
                    '@/components/Financiero/Bancos/Procesos/DepositosRetirosTransferencias/ActualizarTransaccionesBancariasComponent.vue'
                ),
            meta: {
                title: 'Editar Transaccion Bancaria' + _TITLE,
            },
        },
        // REPORTES
        {
            path: 'financiero/bancos/reportes',
            name: 'Reportes',
            component: () =>
                import(
                    '@/views/Financiero/Bancos/ReportesView.vue'
                ),
            meta: {
                title: 'Reportes' + _TITLE,
            },
        },
        // ADMINISTRACION
        {
            path: 'financiero/bancos/administracion',
            name: 'AdministracionBancos',
            component: () =>
                import(
                    '@/views/Financiero/Bancos/AdministracionView.vue'
                ),
            meta: {
                title: 'AdministracionBancos' + _TITLE,
            },
        },

    ],

},
{
    path: '/login',
    name: 'login',
    component: () =>
        import('./views/LoginView.vue'),
},
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

const DEFAULT_TITLE = 'Acoasmi El Roble';
router.afterEach(to => {
    document.title = to.meta.title || DEFAULT_TITLE;
});

function verifyLoggedUser(to, from, next) {
    if (localStorage.getItem('jwt')) next();
    else next('/login');
}

export default router;